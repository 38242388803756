import { wagmiConfig } from '$libs/wagmi';
import { getExpectedNetworkId } from '$libs/wagmi/network';
import { getChainId } from '@wagmi/core';

const getBlockExplorerDomain = () => {
  const chainId = getExpectedNetworkId();
  switch (chainId) {
    case 167000:
      return `https://taikoscan.io`;
    // case 167008:
    //   return `https://explorer.katla.taiko.xyz`;
    case 167009:
      return `https://hekla.taikoscan.network`;
    default:
      return `https://taikoscan.io`;
  }
};

export const generateBlockExplorerUrl = (txnHash: string) => {
  const explorerDomain = getBlockExplorerDomain();
  return `${explorerDomain}/tx/${txnHash}`;
};

export const generateTokenExplorerUrl = (address: string) => {
  const explorerDomain = getBlockExplorerDomain();
  return `${explorerDomain}/token/${address}`;
};
